var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增栏目","width":1000},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 3 },"wrapper-col":{ span: 18 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"栏目名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入栏目名称' },
              { max: 200, message: '最多200个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入栏目名称' },\n              { max: 200, message: '最多200个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"栏目内容"}},[_c('wang-editor-frequently-asked-question',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content', {
            rules: [
              { required: true, message: '请输入栏目内容' },
              { max: 2000, message: '最多2000个字符' } ]
          }]),expression:"['content', {\n            rules: [\n              { required: true, message: '请输入栏目内容' },\n              { max: 2000, message: '最多2000个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }